/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    roles?: Set<string>;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    application?: AccountMembershipPojo.ApplicationEnum;
    otherData?: Array<object>;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'ADMIN' | 'SYSTEM_SUPPORT' | 'EME' | 'MVRD' | 'VRPS' | 'CONTROLLER' | 'MONITOR';
    export const AccountTypeEnum = {
        Admin: 'ADMIN' as AccountTypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as AccountTypeEnum,
        Eme: 'EME' as AccountTypeEnum,
        Mvrd: 'MVRD' as AccountTypeEnum,
        Vrps: 'VRPS' as AccountTypeEnum,
        Controller: 'CONTROLLER' as AccountTypeEnum,
        Monitor: 'MONITOR' as AccountTypeEnum
    };
    export type PermissionsEnum = 'CREATE_USER' | 'CREATE_ADMIN_USER' | 'APPROVE_USER' | 'APPROVE_ADMIN_USER' | 'VIEW_USER' | 'ACTIVATE_USER' | 'DEACTIVATE_USER' | 'ACTIVATE_ADMIN' | 'DEACTIVATE_ADMIN' | 'UPDATE_SETTING' | 'VIEW_SETTING' | 'VIEW_LOG' | 'UPDATE_USER';
    export const PermissionsEnum = {
        CreateUser: 'CREATE_USER' as PermissionsEnum,
        CreateAdminUser: 'CREATE_ADMIN_USER' as PermissionsEnum,
        ApproveUser: 'APPROVE_USER' as PermissionsEnum,
        ApproveAdminUser: 'APPROVE_ADMIN_USER' as PermissionsEnum,
        ViewUser: 'VIEW_USER' as PermissionsEnum,
        ActivateUser: 'ACTIVATE_USER' as PermissionsEnum,
        DeactivateUser: 'DEACTIVATE_USER' as PermissionsEnum,
        ActivateAdmin: 'ACTIVATE_ADMIN' as PermissionsEnum,
        DeactivateAdmin: 'DEACTIVATE_ADMIN' as PermissionsEnum,
        UpdateSetting: 'UPDATE_SETTING' as PermissionsEnum,
        ViewSetting: 'VIEW_SETTING' as PermissionsEnum,
        ViewLog: 'VIEW_LOG' as PermissionsEnum,
        UpdateUser: 'UPDATE_USER' as PermissionsEnum
    };
    export type ApplicationEnum = 'VRPS' | 'MVRD' | 'HVAS' | 'EME' | 'MONITOR' | 'HGS';
    export const ApplicationEnum = {
        Vrps: 'VRPS' as ApplicationEnum,
        Mvrd: 'MVRD' as ApplicationEnum,
        Hvas: 'HVAS' as ApplicationEnum,
        Eme: 'EME' as ApplicationEnum,
        Monitor: 'MONITOR' as ApplicationEnum,
        Hgs: 'HGS' as ApplicationEnum
    };
}


