/* tslint:disable:typedef no-switch-case-fall-through */
import {KeycloakService} from 'keycloak-angular';
import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from './shared/authentication/authentication-service.service';
import {
  ActivatedRoute,
  ActivationEnd,
  ActivationStart,
  Data,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import {Subject} from 'rxjs';
import {User} from './shared/models/user/user.model';
import {PageManager} from './shared/misc/page-manager';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  user: User | undefined;
  stop$ = new Subject();
  userResponse = false;
  routeData: Data | null = null;
  backLink: string | undefined;
  backLinkTitle: string | undefined;
  showBackLink: string | undefined;
  loading: boolean = false;

  constructor(
      public router: Router,
      public keycloakService: KeycloakService,
      private authenticationService: AuthenticationService,
      private keycloak: KeycloakService,
      private pageManger: PageManager,
      private activatedRoute: ActivatedRoute,
      public title: Title,
  ) {
    this.router.events.subscribe((value) => {
      if (value instanceof ActivationEnd) {
        // console.log(value.snapshot.url)
        this.routeData = value.snapshot.data;
        this.title.setTitle(`Vrps | ${this.routeData?.title || 'Vrps'}`)
      } else if (value instanceof NavigationStart) {
        this.loading = true;
      } else if (value instanceof NavigationEnd) {
        this.loading = false;
      }
    });

    this.authenticationService.fetchUser().pipe(takeUntil(this.stop$))
      .subscribe((user) => {
        // console.log(user);
        this.user = user;
        this.userResponse = true;
      }, error => {
        this.userResponse = true;
      })
    this.activatedRoute.params.subscribe(value => {
      this.backLink = value['backLink'];
      this.backLinkTitle = value['backLinkTitle'];
      this.showBackLink = value['showBackLink'];
    })
  }

  ngOnInit(): void {
    this.setPageTitle();
  }

  userInitialized() {
    return !this.userResponse;
  }

  ngOnDestroy() {
    this.stop$.next(false);
    this.stop$.complete();
    this.pageManger.clearAllData();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    const element = document.getElementById('navbar-top');
    if (window.pageYOffset > 45 && element) {
      element.classList.add('scrolled');
    } else {
      if (!this.routeData?.isExtranet) {
        if (element) {
          element.classList.remove('scrolled');
          element.classList.remove('scrolled');
        }
      }
    }
  }

  async login() {
     this.authenticationService.login({
      redirectUri: window.location.origin + '/dashboard',
      prompt: 'login'

    });
    // this.authenticationService.logout(
    //   this.keycloak.getKeycloakInstance().createLoginUrl({
    //     redirectUri: window.location.origin + '/dashboard',
    //     prompt: 'login',
    //   })
    // );
  }

  private setPageTitle(): void {
    const defaultPageTitle = environment.brandName;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;

        if (!child) {
          return this.activatedRoute.snapshot.data.title || defaultPageTitle;
        }

        while (child.firstChild) {
          child = child.firstChild;
        }

        if (child.snapshot.data.title) {
          return child.snapshot.data.title || defaultPageTitle;
        }
      })
    ).subscribe((title: string) => this.title.setTitle(title));
  }
}
