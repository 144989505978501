/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PortalAccountDto { 
    name: string;
    type: PortalAccountDto.TypeEnum;
    email?: string;
    code?: string;
    phoneNumber?: string;
    application?: PortalAccountDto.ApplicationEnum;
    altPhoneNumber?: string;
    streetName?: string;
    houseNumber?: string;
    zipCode?: string;
}
export namespace PortalAccountDto {
    export type TypeEnum = 'ADMIN' | 'SYSTEM_SUPPORT' | 'EME' | 'MVRD' | 'VRPS' | 'CONTROLLER' | 'MONITOR';
    export const TypeEnum = {
        Admin: 'ADMIN' as TypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as TypeEnum,
        Eme: 'EME' as TypeEnum,
        Mvrd: 'MVRD' as TypeEnum,
        Vrps: 'VRPS' as TypeEnum,
        Controller: 'CONTROLLER' as TypeEnum,
        Monitor: 'MONITOR' as TypeEnum
    };
    export type ApplicationEnum = 'VRPS' | 'MVRD' | 'HVAS' | 'EME' | 'MONITOR' | 'HGS';
    export const ApplicationEnum = {
        Vrps: 'VRPS' as ApplicationEnum,
        Mvrd: 'MVRD' as ApplicationEnum,
        Hvas: 'HVAS' as ApplicationEnum,
        Eme: 'EME' as ApplicationEnum,
        Monitor: 'MONITOR' as ApplicationEnum,
        Hgs: 'HGS' as ApplicationEnum
    };
}


