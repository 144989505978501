/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ManageApprovalRequestDto { 
    id: number;
    actionType: ManageApprovalRequestDto.ActionTypeEnum;
    comment: string;
}
export namespace ManageApprovalRequestDto {
    export type ActionTypeEnum = 'APPROVE' | 'DISAPPROVE';
    export const ActionTypeEnum = {
        Approve: 'APPROVE' as ActionTypeEnum,
        Disapprove: 'DISAPPROVE' as ActionTypeEnum
    };
}


