import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";
import {KeycloakService} from "keycloak-angular";
import {User} from "../../../shared/models/user/user.model";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  user: User;

  constructor(
    private authenticationService: AuthenticationService,
    private keycloak: KeycloakService,
  ) {
  }

  ngOnInit(): void {
    this.authenticationService.getUser().subscribe((value) => {
      this.user = value;

      // this.login();
    });
  }

  async login() {
    // this.authenticationService.logout(
    //   this.keycloak.getKeycloakInstance().createLoginUrl({
    //     redirectUri: window.location.origin + '/dashboard',
    //     prompt: 'login',
    //   })
    // );
    this.authenticationService.login({
      redirectUri:window.location.origin + '/dashboard',
          prompt: 'login',
    })
  }
}
