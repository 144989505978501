/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OtpRequestDto { 
    type: OtpRequestDto.TypeEnum;
    deliveryMode: OtpRequestDto.DeliveryModeEnum;
}
export namespace OtpRequestDto {
    export type TypeEnum = 'USER_REGISTRATION' | 'PHONE_NUMBER_UPDATE' | 'GENERAL';
    export const TypeEnum = {
        UserRegistration: 'USER_REGISTRATION' as TypeEnum,
        PhoneNumberUpdate: 'PHONE_NUMBER_UPDATE' as TypeEnum,
        General: 'GENERAL' as TypeEnum
    };
    export type DeliveryModeEnum = 'SMS' | 'EMAIL' | 'PHONE_CALL';
    export const DeliveryModeEnum = {
        Sms: 'SMS' as DeliveryModeEnum,
        Email: 'EMAIL' as DeliveryModeEnum,
        PhoneCall: 'PHONE_CALL' as DeliveryModeEnum
    };
}


