<div class="pt-4 pb-5">
    <div class="mt-4 d-dark-none">
        <ng-lottie [options]="{ path: './assets/lottie/404-error.json', loop: false, autoplay: true }" width="380px" height="auto" containerClass="mx-auto mw-100"></ng-lottie>
    </div>
    <div class="mt-4 d-light-none">
        <ng-lottie [options]="{ path: './assets/lottie/404-error-white.json', loop: false, autoplay: true }" width="380px" height="auto" containerClass="mx-auto mw-100"></ng-lottie>
    </div>
    <div class="text-center">
        <div class="fs-26 fw-bold mb-4">Page Not Found</div>
  
        <button (click)="goBackHome()" class="btn btn-primary mb-3"><span class="iconify icon" data-icon="bi:arrow-left"></span> Go back home</button>
    </div>
</div>  