/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountTypeRole } from './portal-account-type-role';
import { PortalAccountMembership } from './portal-account-membership';
import { PortalUser } from './portal-user';


export interface PortalAccountMemberRole { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: PortalAccountMemberRole.StatusEnum;
    deactivatedBy?: PortalUser;
    membership?: PortalAccountMembership;
    role?: PortalAccountTypeRole;
}
export namespace PortalAccountMemberRole {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
}


