/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityLogSearchFilter { 
    offset?: number;
    limit?: number;
    order?: ActivityLogSearchFilter.OrderEnum;
    orderColumn?: string;
    userName?: string;
    phoneNumber?: string;
    recipient?: string;
    userType?: string;
    activity?: ActivityLogSearchFilter.ActivityEnum;
    status?: ActivityLogSearchFilter.StatusEnum;
    action?: ActivityLogSearchFilter.ActionEnum;
    startDate?: string;
    endDate?: string;
    ipAddress?: string;
    description?: string;
}
export namespace ActivityLogSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type ActivityEnum = 'LOGIN' | 'LOGOUT' | 'CREATE_USER' | 'REMOVE_ROLE' | 'ADD_ROLE' | 'ACTIVATE_USER' | 'DEACTIVATE_USER' | 'CREATE_ADMIN' | 'REQUEST_USER_ACTIVATION' | 'REQUEST_USER_DEACTIVATION' | 'APPROVE_REQUEST' | 'DISAPPROVE_REQUEST';
    export const ActivityEnum = {
        Login: 'LOGIN' as ActivityEnum,
        Logout: 'LOGOUT' as ActivityEnum,
        CreateUser: 'CREATE_USER' as ActivityEnum,
        RemoveRole: 'REMOVE_ROLE' as ActivityEnum,
        AddRole: 'ADD_ROLE' as ActivityEnum,
        ActivateUser: 'ACTIVATE_USER' as ActivityEnum,
        DeactivateUser: 'DEACTIVATE_USER' as ActivityEnum,
        CreateAdmin: 'CREATE_ADMIN' as ActivityEnum,
        RequestUserActivation: 'REQUEST_USER_ACTIVATION' as ActivityEnum,
        RequestUserDeactivation: 'REQUEST_USER_DEACTIVATION' as ActivityEnum,
        ApproveRequest: 'APPROVE_REQUEST' as ActivityEnum,
        DisapproveRequest: 'DISAPPROVE_REQUEST' as ActivityEnum
    };
    export type StatusEnum = 'PENDING' | 'FAILED' | 'SUCCESSFUL';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Failed: 'FAILED' as StatusEnum,
        Successful: 'SUCCESSFUL' as StatusEnum
    };
    export type ActionEnum = 'LOGIN' | 'LOGOUT' | 'CREATE_USER' | 'REMOVE_ROLE' | 'ADD_ROLE' | 'ACTIVATE_USER' | 'DEACTIVATE_USER' | 'CREATE_ADMIN' | 'REQUEST_USER_ACTIVATION' | 'REQUEST_USER_DEACTIVATION' | 'APPROVE_REQUEST' | 'DISAPPROVE_REQUEST';
    export const ActionEnum = {
        Login: 'LOGIN' as ActionEnum,
        Logout: 'LOGOUT' as ActionEnum,
        CreateUser: 'CREATE_USER' as ActionEnum,
        RemoveRole: 'REMOVE_ROLE' as ActionEnum,
        AddRole: 'ADD_ROLE' as ActionEnum,
        ActivateUser: 'ACTIVATE_USER' as ActionEnum,
        DeactivateUser: 'DEACTIVATE_USER' as ActionEnum,
        CreateAdmin: 'CREATE_ADMIN' as ActionEnum,
        RequestUserActivation: 'REQUEST_USER_ACTIVATION' as ActionEnum,
        RequestUserDeactivation: 'REQUEST_USER_DEACTIVATION' as ActionEnum,
        ApproveRequest: 'APPROVE_REQUEST' as ActionEnum,
        DisapproveRequest: 'DISAPPROVE_REQUEST' as ActionEnum
    };
}


