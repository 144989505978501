/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountMemberRole } from './portal-account-member-role';
import { PortalUser } from './portal-user';


export interface PortalAccountTypeRole { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: PortalAccountTypeRole.StatusEnum;
    deactivatedBy?: PortalUser;
    name?: string;
    displayName?: string;
    accountType?: PortalAccountTypeRole.AccountTypeEnum;
    application?: PortalAccountTypeRole.ApplicationEnum;
    portalAccountMemberRoles?: Array<PortalAccountMemberRole>;
}
export namespace PortalAccountTypeRole {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
    export type AccountTypeEnum = 'ADMIN' | 'SYSTEM_SUPPORT' | 'EME' | 'MVRD' | 'VRPS' | 'CONTROLLER' | 'MONITOR';
    export const AccountTypeEnum = {
        Admin: 'ADMIN' as AccountTypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as AccountTypeEnum,
        Eme: 'EME' as AccountTypeEnum,
        Mvrd: 'MVRD' as AccountTypeEnum,
        Vrps: 'VRPS' as AccountTypeEnum,
        Controller: 'CONTROLLER' as AccountTypeEnum,
        Monitor: 'MONITOR' as AccountTypeEnum
    };
    export type ApplicationEnum = 'VRPS' | 'MVRD' | 'HVAS' | 'EME' | 'MONITOR' | 'HGS';
    export const ApplicationEnum = {
        Vrps: 'VRPS' as ApplicationEnum,
        Mvrd: 'MVRD' as ApplicationEnum,
        Hvas: 'HVAS' as ApplicationEnum,
        Eme: 'EME' as ApplicationEnum,
        Monitor: 'MONITOR' as ApplicationEnum,
        Hgs: 'HGS' as ApplicationEnum
    };
}


