<section class="hero-bg">
  <div class="container py-5 mt-md-5">
    <div class="row pt-5 mt-5 mt-lg-0 align-items-center">
      <div class="col-lg-7">
        <div class="mx-auto" style="max-width: 660px;">
          <h1 class="mb-4 fw-bold" style="font-size: calc(2rem + 1.8vw)!important;">High Availability NIN Verification System (HA-NVS)</h1>

          <p class="mb-3">
            The High Availablility System manages the current state of person records and data transfer across the globe.
          </p>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="hero-img-wrapper text-center bg-transparent ms-auto ms-lg-0" style="max-width: 550px;">
          <img src="../assets/img/mvrd-home-img.svg" class="img-fluid mb-4 d-dark-none" alt="MVRD Homepage Image" />
          <img src="../assets/img/mvrd-home-img-dark.svg" class="img-fluid mb-4 d-light-none" alt="MVRD Homepage Image" />
        </div>
      </div>
    </div>
  </div>
</section>