/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityLogDto { 
    username?: string;
    action: ActivityLogDto.ActionEnum;
    userId?: string;
}
export namespace ActivityLogDto {
    export type ActionEnum = 'LOGIN' | 'LOGOUT' | 'CREATE_USER' | 'REMOVE_ROLE' | 'ADD_ROLE' | 'ACTIVATE_USER' | 'DEACTIVATE_USER' | 'CREATE_ADMIN' | 'REQUEST_USER_ACTIVATION' | 'REQUEST_USER_DEACTIVATION' | 'APPROVE_REQUEST' | 'DISAPPROVE_REQUEST';
    export const ActionEnum = {
        Login: 'LOGIN' as ActionEnum,
        Logout: 'LOGOUT' as ActionEnum,
        CreateUser: 'CREATE_USER' as ActionEnum,
        RemoveRole: 'REMOVE_ROLE' as ActionEnum,
        AddRole: 'ADD_ROLE' as ActionEnum,
        ActivateUser: 'ACTIVATE_USER' as ActionEnum,
        DeactivateUser: 'DEACTIVATE_USER' as ActionEnum,
        CreateAdmin: 'CREATE_ADMIN' as ActionEnum,
        RequestUserActivation: 'REQUEST_USER_ACTIVATION' as ActionEnum,
        RequestUserDeactivation: 'REQUEST_USER_DEACTIVATION' as ActionEnum,
        ApproveRequest: 'APPROVE_REQUEST' as ActionEnum,
        DisapproveRequest: 'DISAPPROVE_REQUEST' as ActionEnum
    };
}


