export * from './activity-log-search-controller.service';
import { ActivityLogSearchControllerService } from './activity-log-search-controller.service';
export * from './approval-request-controller.service';
import { ApprovalRequestControllerService } from './approval-request-controller.service';
export * from './audit-trail-controller.service';
import { AuditTrailControllerService } from './audit-trail-controller.service';
export * from './heath-controller.service';
import { HeathControllerService } from './heath-controller.service';
export * from './keycloak-controller.service';
import { KeycloakControllerService } from './keycloak-controller.service';
export * from './monitoring-controller.service';
import { MonitoringControllerService } from './monitoring-controller.service';
export * from './notification-controller.service';
import { NotificationControllerService } from './notification-controller.service';
export * from './one-time-password-controller.service';
import { OneTimePasswordControllerService } from './one-time-password-controller.service';
export * from './portal-account-controller.service';
import { PortalAccountControllerService } from './portal-account-controller.service';
export * from './setting-controller.service';
import { SettingControllerService } from './setting-controller.service';
export * from './user-controller.service';
import { UserControllerService } from './user-controller.service';
export * from './user-management-controller.service';
import { UserManagementControllerService } from './user-management-controller.service';
export const APIS = [ActivityLogSearchControllerService, ApprovalRequestControllerService, AuditTrailControllerService, HeathControllerService, KeycloakControllerService, MonitoringControllerService, NotificationControllerService, OneTimePasswordControllerService, PortalAccountControllerService, SettingControllerService, UserControllerService, UserManagementControllerService];
