/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUser } from './portal-user';


export interface ApprovalRequestDto { 
    id?: number;
    code?: string;
    user?: PortalUser;
    type?: ApprovalRequestDto.TypeEnum;
    status?: ApprovalRequestDto.StatusEnum;
    createdAt?: string;
    createdBy?: string;
    requestReason?: string;
    reviewReason?: string;
    reviewedBy?: string;
}
export namespace ApprovalRequestDto {
    export type TypeEnum = 'APPROVE_NEW_ADMIN' | 'APPROVE_ADMIN_UPDATE' | 'REACTIVATE_ADMIN' | 'DEACTIVATE_ADMIN' | 'APPROVE_NEW_USER' | 'APPROVE_USER_UPDATE' | 'REACTIVATE_USER' | 'DEACTIVATE_USER';
    export const TypeEnum = {
        ApproveNewAdmin: 'APPROVE_NEW_ADMIN' as TypeEnum,
        ApproveAdminUpdate: 'APPROVE_ADMIN_UPDATE' as TypeEnum,
        ReactivateAdmin: 'REACTIVATE_ADMIN' as TypeEnum,
        DeactivateAdmin: 'DEACTIVATE_ADMIN' as TypeEnum,
        ApproveNewUser: 'APPROVE_NEW_USER' as TypeEnum,
        ApproveUserUpdate: 'APPROVE_USER_UPDATE' as TypeEnum,
        ReactivateUser: 'REACTIVATE_USER' as TypeEnum,
        DeactivateUser: 'DEACTIVATE_USER' as TypeEnum
    };
    export type StatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const StatusEnum = {
        Approved: 'APPROVED' as StatusEnum,
        Pending: 'PENDING' as StatusEnum,
        Disapproved: 'DISAPPROVED' as StatusEnum
    };
}


