import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ActivityLogSearchControllerService } from './api/activity-log-search-controller.service';
import { ApprovalRequestControllerService } from './api/approval-request-controller.service';
import { AuditTrailControllerService } from './api/audit-trail-controller.service';
import { HeathControllerService } from './api/heath-controller.service';
import { KeycloakControllerService } from './api/keycloak-controller.service';
import { MonitoringControllerService } from './api/monitoring-controller.service';
import { NotificationControllerService } from './api/notification-controller.service';
import { OneTimePasswordControllerService } from './api/one-time-password-controller.service';
import { PortalAccountControllerService } from './api/portal-account-controller.service';
import { SettingControllerService } from './api/setting-controller.service';
import { UserControllerService } from './api/user-controller.service';
import { UserManagementControllerService } from './api/user-management-controller.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
