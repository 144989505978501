/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PortalUserDto { 
    firstName?: string;
    lastName?: string;
    email: string;
    phoneNumber?: string;
    dateCreated?: string;
    nin?: string;
    username?: string;
    userId?: string;
    password?: string;
    accountCode?: string;
}

