import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-reason-for-disapproval-dialog',
    templateUrl: './reason-for-disapproval-dialog.component.html',
    styleUrls: ['./reason-for-disapproval-dialog.component.css']
})
export class ReasonForDisapprovalDialogComponent implements OnInit {

    @Input() infoMessage: string = 'Provide a reason for this action';
    @Input() inputLabel: string = 'Enter reason for deactivation';
    @Input() modalHeader: string = 'Deactivate';
    @Input() buttonLabel: string = 'Deactivate';

    @Input() onDisapprove: () => void;
    canDisapprove = true;

    @Output() onSuccessEmitter = new EventEmitter();
    @Output() onRemoveClicked = new EventEmitter();

    constructor(
        private bsModalRef: BsModalRef
    ) {
    }

    ngOnInit(): void {
    }

    close(): void {
        this.onSuccessEmitter.emit();
        this.bsModalRef.hide();
    }

    disapprove() {
        this.canDisapprove = false;
        this.onDisapprove?.call(null);
        this.onRemoveClicked.emit();
    }

}
