<div class="d-flex align-items-center">
  <p class="m-0"> Show </p>
  <div class="px-1 mx-2 d-flex justify-content-between align-items-center form-group">
    <select class="form-control py-2 px-2" [(ngModel)]="pageLength">
      <option [selected]="i == 0" *ngFor="let val of pageLengths; let i = index" [value]="val">
        {{val}}
      </option>
    </select>
  </div>
  <p class="m-0"> per page</p>
</div>
