import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {LandingPageComponent} from "./modules/extranet/landing-page/landing-page.component";
import {LoggedInGuard} from "./shared/guard/logged-in.guard";
import {ForbiddenComponent} from "./modules/extranet/error-pages/forbidden/forbidden.component";
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import {HomeComponent} from "./modules/extranet/home/home.component";
import {MultiFactorAuthenticationGuard} from "./shared/guard/multi-factor-authentication-guard.service";



const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        data: {
            isExtranet: true,
            fixedHeader: true
        },
    },

    // {
    //     path: 'authentication',
    //     pathMatch: 'full',
    //     component: AuthenticationComponent,
    //     canActivate: [LoggedInGuard],
    //     data: {
    //         hideLayout: true,
    //         // fixedHeader: true
    //     },
    // },

    {
        path: '',
        loadChildren: () =>
            import('./modules/intranet/intranet.module').then(
                (m) => m.IntranetModule
            ),
        canActivate: [LoggedInGuard, MultiFactorAuthenticationGuard],
        data: {
            isExtranet: false
        },
    },
    {
        path: 'login',
        pathMatch: 'full',
        // canActivate: [LoggedInGuard],
        component: LandingPageComponent,
        data: {
            isExtranet: true
        },
    },
    {
        path: '2fa/fingerprint',
        data: {
            isExtranet: true,
            hideFooter: true,
            hideIntranetHeader: true
        },
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./shared/fingerprint-authentication/fingerprint-authentication-routing.module').then(m => m.FingerprintAuthenticationRoutingModule),
    },
    {
        path: 'download-plugin',
        data: {
            isExtranet: true,
            hideIntranetHeader: true
        },
        canActivate: [],
        loadChildren: () => import('./modules/extranet/biometric-auth-plugin/biometric-auth-plugin.module').then(m => m.BiometricAuthPluginModule),
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
        data: {
            isExtranet: true
        },
    },
    {
        path: '**',
        component: NotFoundComponent,
        data: {
            dynamicLayout: true,
            isExtranet: true
        },
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
