/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Account } from './account';
import { MembershipPojo } from './membership-pojo';
import { PortalAccountMemberRole } from './portal-account-member-role';


export interface PortalUserPojo { 
    firstName?: string;
    lastName?: string;
    otherNames?: string;
    email?: string;
    phoneNumber?: string;
    displayName?: string;
    preferredName?: string;
    status?: PortalUserPojo.StatusEnum;
    id?: number;
    memberships?: Array<MembershipPojo>;
    createdAt?: string;
    roles?: Array<PortalAccountMemberRole>;
    nin?: string;
    accounts?: Array<Account>;
    userId?: string;
}
export namespace PortalUserPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
}


