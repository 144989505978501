import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {Constant} from '../models/enums/constants';
import {UserAccount} from '../models/user-account.model';
import {User} from "../models/user/user.model";


@Injectable({
    providedIn: 'root'
})
export class PageManager {
    public currentUserAccount$: BehaviorSubject<UserAccount>;
    public currentUser$: BehaviorSubject<User>;

  private _storeName = '_session-store';
  private store: any;
  private _userScope = 'USER';
  private _userAccountScope = 'USER_ACCOUNT';
  private _currentUserKey = 'currentUser';
  private _currentAccountKey = 'currentAccount';

  constructor(public router: Router) {
    this.currentUserAccount$ = new BehaviorSubject<UserAccount>(this.getCurrentUserAccountFromStorage());
    this.currentUser$ = new BehaviorSubject<User>(this.getCurrentUserFromStorage());
  }



  getCurrentUserAccountFromStorage() {
    return this.getData(this._userAccountScope, this._currentAccountKey, Constant.Storage.LOCAL);
  }

  getCurrentUserFromStorage() {
    return this.getData(this._userScope, this._currentUserKey, Constant.Storage.LOCAL);
  }

  public get currentUser(): User {
    return this.getData(this._userScope, this._currentUserKey, Constant.Storage.LOCAL);
  }

  public get currentUserAccount(): UserAccount {
    return this.getData(this._userAccountScope, this._currentAccountKey, Constant.Storage.LOCAL);
  }

  public setCurrentUserAccount(userAccount: UserAccount) {
    if (!userAccount) {
      this.currentUserAccount$.next(this.getCurrentUserAccountFromStorage());
    } else {
      this.currentUserAccount$.next(userAccount);
      this.storeData(this._userAccountScope, this._currentAccountKey, userAccount, Constant.Storage.LOCAL);
    }
  }

  public setCurrentUser(user: User) {
    if (!user) {
      this.currentUser$.next(this.getCurrentUserFromStorage());
    } else {
      this.currentUser$.next(user);
      this.storeData(this._userScope, this._currentUserKey, user, Constant.Storage.LOCAL);
    }
  }

  getStore(storageType: Constant.Storage) {
    const sessionStore = this.isLocal(storageType) ? localStorage.getItem(this._storeName) : sessionStorage.getItem(this._storeName);
    return sessionStore ? JSON.parse(sessionStore) : {};
  }

  public storeData(scope: string, key: string, data: any, storageType: Constant.Storage = Constant.Storage.LOCAL): boolean {
    this.store = this.getStore(storageType);
    if (!this.store[scope]) {
      this.store[scope] = {};
    }
    this.store[scope][key] = JSON.stringify(data);

    this.persist(storageType);

    return this.store[scope];
  }

  public getData(scope: string, key: string, storageType: Constant.Storage = Constant.Storage.LOCAL): any | boolean {

    this.store = this.getStore(storageType);
    if (!this.store[scope] || !this.store[scope][key]) {
      return false;
    }
    return JSON.parse(this.store[scope][key]);
  }

  public removeData(scope: string, key: string, storageType: Constant.Storage = Constant.Storage.LOCAL): boolean {

    this.store = this.getStore(storageType);

    if (!this.store[scope] || !this.store[scope][key]) {
      return false;
    }
    delete this.store[scope][key];

    this.persist(storageType);

    return true;
  }

  public clearAllData() {
      this.currentUser$.next(null)
      this.currentUserAccount$.next(null)
      localStorage.clear();
      sessionStorage.clear();
  }

  public setIntegration(userAccount: UserAccount) {
    let account = this.currentUserAccount$.getValue();
    this.setCurrentUserAccount(account);
  }

  private persist(storageType: Constant.Storage) {
    if (this.isLocal(storageType)) {
      localStorage.setItem(this._storeName, JSON.stringify(this.store));
    } else {
      sessionStorage.setItem(this._storeName, JSON.stringify(this.store));
    }
  }

  isLocal(type: Constant.Storage): boolean {
    return type === Constant.Storage.LOCAL;
  }
}
