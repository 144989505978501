import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from '@angular/platform-browser';
import {KeycloakAngularModule, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {LottieModule} from 'ngx-lottie';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PopoverModule} from 'ngx-bootstrap/popover';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ForbiddenComponent} from "./modules/extranet/error-pages/forbidden/forbidden.component";
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import {SiteHeaderComponent} from './modules/layout/site-header/site-header.component';
import {SiteFooterComponent} from './modules/layout/site-footer/site-footer.component';
import {LandingPageComponent} from './modules/extranet/landing-page/landing-page.component';
import {environment} from "../environments/environment";
import {HomeComponent} from './modules/extranet/home/home.component';
import {IntranetSideHeaderComponent} from './modules/layout/intranet-site-header/intranet-side-header.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthenticationService} from "./shared/authentication/authentication-service.service";
import {AuditTrailControllerService, BASE_PATH} from "../../sdk/hvas-api-sdk";
import {HttpInterceptorService} from "./shared/authentication/http-interceptor.service";
import {AlertConfig, AlertModule} from "ngx-bootstrap/alert";
import {SharedModule} from "./shared/shared.module";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {CommonModule, TitleCasePipe} from "@angular/common";
import {AuthenticationComponent} from './modules/extranet/authentication/authentication.component';
import {filter, mergeMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {FingerprintAuthModule} from "./modules/extranet/fingerprint-auth/fingerprint-auth.module";

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}


function initializeKeycloak(keycloak: KeycloakService,
                            authenticationService: AuthenticationService,
                            loggerService: AuditTrailControllerService) {
    const STORAGE_KEY = 'isFirstLogin';
    let isFirstLogin =
        localStorage.getItem(STORAGE_KEY) === null || localStorage.getItem(STORAGE_KEY) == 'true';

    keycloak.keycloakEvents$
        .pipe(
            filter((value) => value.type === KeycloakEventType.OnAuthSuccess),
            mergeMap((value) => {
                return authenticationService
                    .fetchUser()
                    .toPromise()
                    .then((res) => {
                        if (isFirstLogin) {
                            isFirstLogin = false;
                            localStorage.setItem(STORAGE_KEY, 'false');
                            return res;
                        } else {
                            return new Observable();
                        }
                    });
            })
        ).subscribe();
    return () =>
        keycloak.init({
            config: {...environment.keycloakConfig},
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html',
                redirectUri: environment.keycloakConfig.url
            }
        });
}

@NgModule({
    declarations: [AppComponent, ForbiddenComponent, SiteHeaderComponent, SiteFooterComponent, LandingPageComponent, HomeComponent, IntranetSideHeaderComponent, NotFoundComponent, AuthenticationComponent],
    imports: [
        AppRoutingModule, BrowserModule,
        KeycloakAngularModule, HttpClientModule, FormsModule, ReactiveFormsModule,
        BrowserAnimationsModule,
        NgMultiSelectDropDownModule.forRoot(),
        AlertModule,
        SharedModule,
        LottieModule.forRoot({player: playerFactory}),
        PopoverModule.forRoot(),
        TooltipModule.forRoot(),
        FingerprintAuthModule.forRoot()
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
        {
            provide: BASE_PATH,
            useValue: environment.apiBaseUrl
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService, AuthenticationService, AuditTrailControllerService]
        },
        AlertConfig, TitleCasePipe
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
