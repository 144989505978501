/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUser } from './portal-user';


export interface PortalAccount { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: PortalAccount.StatusEnum;
    deactivatedBy?: PortalUser;
    name?: string;
    type?: PortalAccount.TypeEnum;
    code?: string;
    approvalStatus?: PortalAccount.ApprovalStatusEnum;
    applicationType?: PortalAccount.ApplicationTypeEnum;
}
export namespace PortalAccount {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
    export type TypeEnum = 'ADMIN' | 'SYSTEM_SUPPORT' | 'EME' | 'MVRD' | 'VRPS' | 'CONTROLLER' | 'MONITOR';
    export const TypeEnum = {
        Admin: 'ADMIN' as TypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as TypeEnum,
        Eme: 'EME' as TypeEnum,
        Mvrd: 'MVRD' as TypeEnum,
        Vrps: 'VRPS' as TypeEnum,
        Controller: 'CONTROLLER' as TypeEnum,
        Monitor: 'MONITOR' as TypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum
    };
    export type ApplicationTypeEnum = 'VRPS' | 'MVRD' | 'HVAS' | 'EME' | 'MONITOR' | 'HGS';
    export const ApplicationTypeEnum = {
        Vrps: 'VRPS' as ApplicationTypeEnum,
        Mvrd: 'MVRD' as ApplicationTypeEnum,
        Hvas: 'HVAS' as ApplicationTypeEnum,
        Eme: 'EME' as ApplicationTypeEnum,
        Monitor: 'MONITOR' as ApplicationTypeEnum,
        Hgs: 'HGS' as ApplicationTypeEnum
    };
}


