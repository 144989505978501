<div class="container pt-4 pt-md-3 pb-4 mb-2">
  <div class="welcome-wrapper flex-wrap flex-md-nowrap">
    <!-- <div *ngIf="routeData?.showWelcome">
      <h4 class="fw-bold fs-26 mt-4">Welcome to the VRPS setup wizard!</h4>
      <p class="fs-15 mb-4">
        Kindly carefully configure the form below and click on the Next
        button.
      </p>
    </div> -->
    <!-- <div class="d-flex card-bg p-3 mb-3 col-12 col-md-4 col-lg-3 col-xl-2" style="min-width: 230px;">
      <div class="me-2">
        <span class="iconify fs-22 text-header" data-icon="ant-design:user-outlined"></span>
      </div>
      <div>
        <p class="fs-14 fw-bold text-header mb-0 text-truncate">{{user?.firstName}} {{user?.lastName}}</p>
        <p class="fs-14 fw-light mb-0">{{currentAccount?.roles[0]}}</p>
      </div>
    </div> -->
  </div>
  
  <div class="dash-menu" *ngIf="!(routeData?.showWelcome)">
    <div class="dash-container">
      <div class="menu-toggle-btn-wrapper">
        <button class="menu-toggle-btn" data-hvas-target="#dash-navlist" data-hvas-toggle-class="show">
          <span class="iconify icon" data-icon="heroicons-solid:menu"></span>
          Menu
        </button>
      </div>
      
      <ul id="dash-navlist" class="dash-menu-navlist justify-content-center mb-3">
          <li><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/dashboard']"
                 data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Dashboard</a></li>
          <li *ngIf="canViewUsers()"><a [routerLinkActive]="'active'" [routerLink]="['/access-manager']"
                                        class="nav-item" data-hvas-remove-class="show" data-hvas-target="#dash-navlist">Access
              Manager</a></li>
          <li *ngIf="canApproveRequest()"><a [routerLinkActive]="'active'" [routerLink]="['/approval-request']"
                                             class="nav-item" data-hvas-remove-class="show"
                                             data-hvas-target="#dash-navlist">Approval Request</a></li>
          <li *ngIf="canViewAuditTrail()"><a [routerLinkActive]="'active'" [routerLink]="['/audit-trail']"
                                             class="nav-item" data-hvas-remove-class="show"
                                             data-hvas-target="#dash-navlist">Audit Trail</a></li>

      </ul>
    </div>
  </div>
</div>
