/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EditUserDto { 
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    gender?: EditUserDto.GenderEnum;
    roles: { [key: string]: Set<string>; };
    phoneNumber?: string;
}
export namespace EditUserDto {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Unspecified: 'UNSPECIFIED' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
}


