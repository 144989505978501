import {Component, OnInit} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";

@Component({
  selector: 'app-intranet-side-header',
  templateUrl: './intranet-side-header.component.html',
  styleUrls: ['./intranet-side-header.component.css']
})
export class IntranetSideHeaderComponent implements OnInit {

  user: any;
  routeData: any;

  constructor(private router: Router,
              route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private keycloak: KeycloakService) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routeData = route?.root?.firstChild?.firstChild?.snapshot?.data || route?.root?.firstChild?.snapshot?.data;
      }
    })
  }

  ngOnInit(): void {

  }

  async logout() {
    await this.keycloak.getKeycloakInstance().logout({})
  }

  canViewUsers() {
    return this.authenticationService.hasAnyPermission(['VIEW_USER'])
  }

  canViewAuditTrail() {
    return this.authenticationService.hasPermission('VIEW_LOG')
  }

  canApproveRequest() {
    return (this.authenticationService.hasPermission('APPROVE_USER') ||
        this.authenticationService.hasPermission('APPROVE_ADMIN_USER'))
  }
}
