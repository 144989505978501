/**
 * HVAS API
 * The HVAS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountAndRolesDto } from './portal-account-and-roles-dto';


export interface UpdateRolePojo { 
    application?: UpdateRolePojo.ApplicationEnum;
    portalAccount?: string;
    roles?: Array<string>;
    userId?: string;
    portalAccountAndRolesDtos?: Array<PortalAccountAndRolesDto>;
}
export namespace UpdateRolePojo {
    export type ApplicationEnum = 'VRPS' | 'MVRD' | 'HVAS' | 'EME' | 'MONITOR' | 'HGS';
    export const ApplicationEnum = {
        Vrps: 'VRPS' as ApplicationEnum,
        Mvrd: 'MVRD' as ApplicationEnum,
        Hvas: 'HVAS' as ApplicationEnum,
        Eme: 'EME' as ApplicationEnum,
        Monitor: 'MONITOR' as ApplicationEnum,
        Hgs: 'HGS' as ApplicationEnum
    };
}


