import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {KeycloakService} from "keycloak-angular";
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";
import {UserAccount} from "../../../shared/models/user-account.model";
import {PageManager} from "../../../shared/misc/page-manager";
import {User} from "../../../shared/models/user/user.model";
import AccountTypeEnum = AccountMembershipPojo.AccountTypeEnum;
import {Constant} from "../../../models/constant/Constant";
import {
    AccountMembershipPojo,
    ActivityLogDto,
    ActivityLogSearchControllerService, AuditTrailControllerService, UserControllerService
} from "../../../../../sdk/hvas-api-sdk";
import {distinctUntilChanged} from "rxjs/operators";

@Component({
    selector: 'app-site-header',
    templateUrl: './site-header.component.html',
    styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit {
    @Input()
    user: User;
    currentAccount: UserAccount;
    routeData: any;
    logoutUrl;
    applicationName: string = 'HVAS';
    currentUserRoles: string[] = [];

    constructor(private router: Router, route: ActivatedRoute,
                private keycloak: KeycloakService,
                private pageManager: PageManager,
                private userController: UserControllerService,
                private authenticationService: AuthenticationService,

                private activityLogService: AuditTrailControllerService) {
        router.events.forEach(e => {
            if (e instanceof NavigationEnd) {
                this.routeData = route?.root?.firstChild?.snapshot?.data;
            }
        })
    }

    ngOnInit(): void {

        this.pageManager.currentUserAccount$
            .subscribe(userAccount => {
                this.currentAccount = userAccount;
                this.fetchUserRoles();
            });
    }

    async login() {
        this.authenticationService.login({

            redirectUri: window.location.origin + '/dashboard',
            prompt: 'login'

        });
    }

    async dashboard() {
        this.router.navigateByUrl('/dashboard')
    }

    async logout() {
        await this.authenticationService.logout().pipe(distinctUntilChanged()).subscribe(value => {
            this.authenticationService.fetchUser().subscribe(res => {
                // @ts-ignore
                const activityLogDto: ActivityLogDto = {
                    // @ts-ignore
                    userId: res?.userId,
                    username: res?.firstName,
                    action: ActivityLogDto.ActionEnum.Logout
                }
                this.activityLogService.logLogoutAction({auditTrailDto: {}}).pipe(distinctUntilChanged()).subscribe(res => {
                })
                localStorage.clear();
                sessionStorage.clear();
            })
        });
    }

    get AccountType() {
        return AccountTypeEnum
    }

    goToDashboard() {
        this.router.navigate(['/dashboard'])
    }

    setHeaderClass() {
        let headerClass = '';

        if (this.routeData?.fixedHeader) {
            headerClass += ' header-fixed fixed-on-scroll'
        }
        if (this.routeData?.dynamicLayout ? this.user : !(this.routeData?.isExtranet)) {
            headerClass += ' intranet-nav'
        }

        return headerClass;
    }

    fetchUserRoles() {
        this.currentUserRoles = [];
        this.user?.accounts.forEach(account => {
            if (account.application == this.applicationName) {
                account.roles.forEach(role => {
                    this.currentUserRoles.push(role);
                });
                // this.currentUserRoles.push(account.roles.map(role => role).join(", "));
            }
        })
    }
}
